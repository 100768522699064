import React, { useState, useEffect } from "react";
import "./MessageInput.css";

// Initialize SpeechRecognition API
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

recognition.continuous = false; // Stop automatically after one phrase
recognition.interimResults = false; // Get only final results
recognition.lang = "en-US"; // Set the language

const MessageInput = ({ addMessage }) => {
  const [input, setInput] = useState("");
  const [isListening, setIsListening] = useState(false);

  // SpeechSynthesis - Speak a message
  const speakMessage = (text) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-US";
    synth.speak(utterance);
  };

  const handleSubmit = async () => {
    try {
      const apiKey = "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";

      const messages = [
        {
          role: "user",
          content: `Grammar check: "${input}"`,
        },
      ];

      await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",
          max_tokens: 150,
          messages: messages,
        }),
      }).then((r) => {
        r.json().then(async (r) => {
          try {
            const messages = [
              {
                role: "user",
                content: `Friendly answer the chat: "${input} ".`,
              },
            ];

            await fetch("https://api.openai.com/v1/chat/completions", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiKey}`,
              },
              body: JSON.stringify({
                model: "gpt-4o-mini",
                max_tokens: 150,
                messages: messages,
              }),
            }).then((s) => {
              s.json().then((t) => {
                const userMessage = r.choices[0].message.content;
                const botMessage = t.choices[0].message.content;

                // Add the messages and reset input
                addMessage([
                  [input, userMessage, "human"],
                  [input, botMessage, "bot"],
                ]);

                // Speak the bot's response
                speakMessage(botMessage);

                setInput("");
              });
            });
          } catch (error) {
            addMessage(["An error occurred while fetching data.", ""]);
          }
        });
      });
    } catch (error) {
      addMessage(["An error occurred while fetching data.", ""]);
    }
  };

  // Handle Speech Recognition result
  useEffect(() => {
    recognition.onresult = (event) => {
      const speechToText = event.results[0][0].transcript;
      setInput(speechToText);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error detected: " + event.error);
      setIsListening(false);
    };

    // Automatically submit form after speech recognition ends
    recognition.onend = () => {
      setIsListening(false);
      if (input.trim() !== "") {
        handleSubmit(); // Automatically submit after recognition ends
      }
    };
  }, [input]);

  // Start listening to speech
  const startListening = () => {
    setIsListening(true);
    recognition.start();
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} className="message-input">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type a message..."
      />
      <button type="submit" onClick={handleSubmit}>
        Send
      </button>
      <button type="button" onClick={startListening}>
        {isListening ? "Listening..." : "🎙️ Speak"}
      </button>
    </form>
  );
};

export default MessageInput;
